.outgoing-text-editor {
  padding: 10px;

  textarea {
    height: 62px;
    width: 100%;
    resize: none;
    margin-bottom: 10px;
  }

  .send-btn {
    float: right;
  }
}