.spinner-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  background: #fff;
  opacity: 0.85;
}

