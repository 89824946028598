@import '../variables';

.contact-list {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .list-header {
    color: $primaryDarkGray;
    flex: 0 0 60px;
    padding: 0 0 10px 0;

    > .inner {
      height: 100%;
      padding: 14px 10px 0 10px;
      border-bottom: 1px solid #ced4da;
    }

    .add-contact {
      float: right;
    }
  }

  .contacts-container {
    flex: 1;
    height: 100%;
    position:relative;
  }
}