@import '../variables';

.attachment {
  position: relative;

  img, .placeholder {
    border-radius: 6px;
    border: 1px solid #eee;
  }

  .placeholder {
    background: #eee;
    display: inline-block;
    position: absolute;
    z-index: 1;

    .spinner {
      color: #aaa;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
    cursor: zoom-in;

    position: relative;
    z-index: 2;

    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.75;
    }
  }

  &.shown {
    .placeholder {
      display: none;
    }
  }

  &.show-animated {
    img {
      -webkit-animation: attachment-img-show-animation 0.3s ease-in-out;
      animation: attachment-img-show-animation 0.3s ease-in-out;
    }

    .placeholder  {
      -webkit-animation: attachment-placeholder-hide-animation 0.5s ease-in-out;
      animation: attachment-placeholder-hide-animation 0.5s ease-in-out;

      visibility: hidden;
    }
  }

  .error {
    svg {
      position: relative;
      top: -1px;
      max-width: 72px;
      max-height: 72px;
      opacity: 0.7;
      margin-bottom: 6px;
    }

    color: $red;
  }
}

@keyframes attachment-img-show-animation {
  from { opacity: 0; }
  to { opacity: 1; }
}
@-webkit-keyframes attachment-img-show-animation {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes attachment-placeholder-hide-animation {
  from { opacity: 1; visibility: visible; }
  70% { opacity: 1; visibility: visible; }
  to { opacity: 0; visibility: hidden; }
}
@-webkit-keyframes attachment-placeholder-hide-animation {
  from { opacity: 1; visibility: visible; }
  70% { opacity: 1; visibility: visible; }
  to { opacity: 0; visibility: hidden; }
}

.text.in .attachment .placeholder {
  left: 0;
}

.text.out .attachment .placeholder {
  right: 0;
}