@import '../variables';

.dialog-header {
  color: $primaryDarkGray;

  .edit-btn {
    top: -2px;
    position: relative;
    margin-left: 6px;
    color: #d6d6d6;

    &:hover {
      color: #999;
    }
  }
}