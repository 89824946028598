.attachment-selector {
  display: inline-block;

  .image-btn {
    border-radius: .25rem 0 0 .25rem;
    transition: border-radius .2s ease-in-out;
  }

  .remove-image-btn {
    border-radius: 0 .25rem .25rem 0;
    transition: border-radius .2s ease-in-out;
  }
}