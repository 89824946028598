@import '../variables';

.contact-list-item {
  padding: 12px 10px;
  border-bottom: 1px solid #e2e3e5;
  cursor: pointer;

  .name {
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .unread-texts {
    display: inline-block;
    float: right;
    padding: 0 8px;
    border-radius: 6px;
    font-weight: 600;
    background: $orange;
    color: #fff;
  }

  .phone-number {
    font-size: 13px;
  }

  &:not(.active):hover {
    background: darken(#f3f3f3, 5%);
  }

  &.active {
    background: $green;

    .name {
      font-weight: 600;
    }
  }

  &.has-unread-texts {
    .name {
      font-weight: 600;
    }
  }
}