.spinner {
  -webkit-animation: spinner-animation 1.5s linear infinite;
  animation: spinner-animation 1.5s linear infinite;
}

@keyframes spinner-animation {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
@-webkit-keyframes spinner-animation {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}