.dialog-texts {
  position: absolute;
  width: 100%;
  height: 100%;

  .dialog-texts-container {
    padding: 0 10px;

    .fetching-history {
      position: relative;

      > .inner {
        text-align: center;
        opacity: 0.5;
        position: absolute;
        width: 100%;
      }
    }
  }
}