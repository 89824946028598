.middle-center {
  display: table;
  width: 100%;
  height: 100%;
}

.middle-center-inner {
  display: table-cell;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
}