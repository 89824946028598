@import '../variables';

.text {
  margin-bottom: 14px;

  > div:not(:first-child) {
    margin-top: 4px;
  }

  .bubble {
    display: inline-block;
    max-width: 70%;
    text-align: left;
    padding: 8px 12px;
    border-radius: 6px;

    a {
      color: #fff;
      text-decoration: underline;
      transition: opacity .2s ease-in-out;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .timestamp, .sending, .send-error {
    font-size: 12px;
  }

  .timestamp {
    color: #5a6268;
  }

  .sending {
    font-style: italic;
    color: #848484;
  }

  .send-error {
    color: red;
  }

  &.in {
    .bubble {
      background: darken($primaryBlue, 5%);
      color: #fff;
    }
  }

  &.out {
    text-align: right;

    .bubble {
      background: darken($green, 10%);
      color: #fff;
    }
  }
}