@import '../variables';

.header {
  padding: 10px 12px;
  background: $primaryBlue;
  color: #fff;
  width: 100%;

  .logo, .user, .log-out-btn {
    display: inline-block;
  }

  .logo {
    font-size: 20px;
    cursor: pointer;

    svg {
      top: -2px;
      position: relative;
      opacity: 0.5;
    }
  }

  .user {
    float: right;
    margin-right: 12px;

    .name {
      font-size: 13px;
    }

    .phone-number {
      font-size: 11px;
    }
  }

  .log-out-btn {
    float: right;

    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }
}