.unauthorized-screen {
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  text-align: center;

  .login-form-container {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;

    .info {
      margin-bottom: 12px;
    }
  }
}