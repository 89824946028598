@import '../variables';

.wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;

  .header-wrapper {
    display: flex;
    flex: 1 1 5%;
    min-height: 3em;
    max-height: 3em;
  }

  .content-wrapper {
    display: flex;
    flex: 1 1 85%;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    overflow: auto;

    flex-direction: row;
  }

  .contact-list-wrapper {
    flex: 0 1 300px;
    height: 100%;
    position:relative;

    background: #f3f3f3;
  }

  .page-wrapper {
    flex: 1;
    height: 100%;
    position:relative;
  }

  .dialog-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}